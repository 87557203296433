import React from 'react'
import { Bolder, Header2, Paragraph } from '@/theme/Typography.styled'
import { ButtonTextPink } from '@/theme/Button.styled'
import Banner1 from '@/components/Banner1/Banner1'

interface Props {
  cat?: boolean
  dog?: boolean
}
const DownloadFormBanner = ({ cat, dog }: Props) => (
  <Banner1>
    <Header2>
      <Bolder>Pamiętaj o ankiecie behawioralnej</Bolder>
    </Header2>
    <Paragraph>
      Wypełnij ankietę behawioralną przed rozpoczęciem konsultacji
    </Paragraph>
    {dog && (
      <ButtonTextPink
        download
        to="https://petschool.pl/download/ankieta-behawioralna-pies.docx"
      >
        Pobierz ankietę - PIES
      </ButtonTextPink>
    )}
    {cat && dog && <br />}
    {cat && (
      <ButtonTextPink
        download
        to="https://petschool.pl/download/ankieta-behawioralna-kot.docx"
      >
        Pobierz ankietę - KOT
      </ButtonTextPink>
    )}
  </Banner1>
)

export default DownloadFormBanner
