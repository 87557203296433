import React from 'react'
// @ts-ignore
import Slider from 'react-slick'
import * as Styled from './ServicesCarousel.styled'
import { Container } from '@/theme/Grid.styled'
import { Bolder } from '@/theme/Typography.styled'
import {
  Services,
  Settings,
} from '@/components/ServicesCarousel/servicesCarousel.constants'
import ServicesCarouselItem from '@/components/ServicesCarousel/ServicesCarouselItem'
import { ServiceInterface } from '@/interfaces/interfaces'

const ServicesCarousel = () => {
  const items = Services.map(
    ({ description, icon, link, title }: ServiceInterface, index: number) => (
      <ServicesCarouselItem
        description={description}
        icon={icon}
        link={link}
        key={index}
        title={title}
      />
    ),
  )
  return (
    <Styled.Wrapper id="oferta">
      <Container>
        <Styled.Header>
          <Bolder>Moja oferta</Bolder>
        </Styled.Header>
        <Styled.Items>
          <Slider {...Settings}>{items}</Slider>
        </Styled.Items>
      </Container>
    </Styled.Wrapper>
  )
}

export default ServicesCarousel
