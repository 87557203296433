// @ts-ignore
import styled from 'styled-components'
import { Container as _Container } from '@/theme/Grid.styled'

// @ts-ignore
import Background from '@/assets/images/background-pink.svg'

export const Wrapper = styled.div`
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: cover;
`

export const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 93px 0;

  @media (max-width: 991px) {
    padding: 54px 0;
  }
`

export const Container = styled(_Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
