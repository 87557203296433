import * as React from 'react'
import AboutMe from '@/components/AboutMe/AboutMe'
import BannerHome from '@/components/BannerHome/BannerHome'
import Layout from '../components/Layout/Layout'
import ServicesCarousel from '@/components/ServicesCarousel/ServicesCarousel'
import DownloadFormBanner from '@/components/DownloadFormBanner/DownloadFormBanner'
import Certificates from '@/components/Certificates/Certificates'

const Head = () => (
  <>
    <title>Pet School - Szkolenia i terapia behawioralna zwierząt</title>
  </>
)
const IndexPage = () => {
  return (
    <>
      <Head />
      <Layout>
        <BannerHome />
        <ServicesCarousel />
        <AboutMe />
        <Certificates />
        <DownloadFormBanner cat={true} dog={true} />
      </Layout>
    </>
  )
}

export default IndexPage
