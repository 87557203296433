// @ts-ignore
import styled from 'styled-components'
import { Color } from '@/theme/colors'
import { Header2 } from '@/theme/Typography.styled'

export const Wrapper = styled.div`
  background-color: ${Color.Pink_2};
  padding-bottom: 107px;

  @media (max-width: 767px) {
    padding-bottom: 60px;
  }
`

export const Header = styled(Header2)`
  padding: 30px 0 60px;
  text-align: center;

  @media (max-width: 767px) {
    padding: 23px 0 17px;
  }
`

export const Items = styled.div``
