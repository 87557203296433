// @ts-ignore
import styled from 'styled-components'
import { Header3, Paragraph } from '@/theme/Typography.styled'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 20px 60px;

  @media (max-width: 991px) {
    padding: 0 0 30px;
  }
`

export const Icon = styled.div`
  margin-bottom: 8px;
`

export const Title = styled(Header3)`
  margin-bottom: 20px;
`

export const Description = styled(Paragraph)`
  margin-bottom: 20px;

  @media (max-width: 767px) {
    margin-bottom: 33px;
  }
`
